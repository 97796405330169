<template>
  <VLayout class="date-pagination-picker">
    <VFlex xs8 sm5 md3 class="mx-auto">
      <VMenu
        v-model="showDatePicker"
        :close-on-content-click="false"
        :nudge-right="10"
        lazy
        transition="scale-transition"
        hide-details
        offset-y
        full-width
        min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <BaseInput
            :value="displayDate"
            class="pagination-date-picker-input"
            prepend-icon="event"
            readonly
            outline
            color="white"
            dark
            hide-details
            single-line
            placeholder="Select Date"
            v-on="on"
          />
        </template>
        <VDatePicker v-model="date" @input="showDatePicker = false" />
      </VMenu>
    </VFlex>
  </VLayout>
</template>

<script>
import { VDatePicker } from 'vuetify/lib'
import dayjs from 'dayjs'
import _get from 'lodash/get'

export default {
  name: 'PaginationDatePicker',
  components: {
    VDatePicker,
  },
  data() {
    return {
      date: null,
      showDatePicker: false,
    }
  },
  computed: {
    queryDate() {
      return _get(this, '$route.query.reportDate')
    },
    displayDate() {
      return dayjs(this.date).format('MM-DD-YYYY')
    },
  },
  watch: {
    date(date) {
      let reportDate = dayjs(date).format('MM-DD-YYYY')
      let queryDate = dayjs(this.queryDate).format('MM-DD-YYYY')

      if (queryDate === reportDate) return
      this.$router.push({
        query: {
          ...this.$route.query,
          reportDate,
        },
      })
    },
  },
  mounted() {
    this.date = this.queryDate
      ? dayjs(this.queryDate).format('YYYY-MM-DD')
      : dayjs().format('YYYY-MM-DD')
  },
}
</script>

<style lang="scss">
.pagination-date-picker-input {
  .v-text-field__slot input {
    font-size: 2rem;
    line-height: 1.3;
  }
  // icon
  .v-input__prepend-outer {
    margin-top: 12px;
  }
}
</style>
