var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('WithReports',{attrs:{"items-per-page":_vm.itemsPerPage,"filters":_vm.filters,"query-params":_vm.queryParams},scopedSlots:_vm._u([{key:"default",fn:function({
        reports,
        areReportsLoading,
        searchReports,
        clearReportsSearch,
        totalPages,
        totalRecords,
        page,
        refreshPage,
      }){return _c('div',{},[_c('PaginationDatePicker'),_vm._t("subtitle"),(_vm.getsDayNav)?_c('DatePagination',{attrs:{"is-loading":areReportsLoading,"items-per-page":_vm.itemsPerPage},on:{"nextDay":_vm.nextDay,"previousDay":_vm.previousDay}}):_vm._e(),(reports.length)?_c('BasePagination',{attrs:{"is-loading":areReportsLoading,"page":page,"total-pages":totalPages,"number-of-records":totalRecords,"items-per-page":_vm.itemsPerPage,"record-type":"Reports"},on:{"nextPage":_vm.nextPage,"previousPage":_vm.previousPage}}):_vm._e(),(_vm.hideFilters && !_vm.noFilters)?_c('BaseButton',{attrs:{"small":"","outline":"","color":"white"},on:{"click":function($event){_vm.forceShowFilters = true}}},[_vm._v("Show Filters & Search")]):(!_vm.noFilters)?[(['sm', 'xs'].includes(_vm.$mq))?_c('BaseButton',{attrs:{"small":"","outline":"","color":"white"},on:{"click":function($event){_vm.forceShowFilters = false}}},[_vm._v("hide Filters & Search")]):_vm._e(),(!_vm.noFilters)?_c('ReportsListFilters',{attrs:{"hide-filters":_vm.hideFilters}}):_vm._e(),_c('VContainer',{attrs:{"pa-0":""}},[_c('VLayout',{attrs:{"justify-center":""}},[_c('VFlex',{attrs:{"xs12":"","sm8":""}},[(_vm.getsSearch)?_c('BaseSearch',{attrs:{"placeholder":"Type to Search Reports","messages":_vm.$route.query.s
                    ? [`Search results for : '${_vm.$route.query.s}'`]
                    : [],"color":"white","value":_vm.$route.query.s},on:{"search":searchReports,"clear":clearReportsSearch}}):_vm._e()],1)],1)],1)]:_vm._e(),_vm._t("header-buttons",null,{"reports":reports,"loading":areReportsLoading,"refreshPage":refreshPage}),_c('transition',{attrs:{"name":"fade-fast","mode":"out-in","appear":""}},[(areReportsLoading)?_c('BaseSpinner'):(reports && reports.length)?_c('ReportsList',{attrs:{"reports":reports}}):(_vm.$route.query.s && !reports.length)?_c('p',[_vm._v("No reports matched the search \""+_vm._s(_vm.$route.query.s)+"\".")]):_c('p',[_vm._v("No reports found for this view. "),(page && page > 1)?[_c('br'),_c('BaseButton',{on:{"click":_vm.previousPage}},[_vm._v("Go Back A Page")])]:_vm._e()],2)],1),_c('transition',{attrs:{"name":"fade-fast","mode":"out-in","appear":""}},[(!areReportsLoading)?_c('BasePagination',{attrs:{"is-loading":areReportsLoading,"page":page,"total-pages":totalPages,"number-of-records":totalRecords,"items-per-page":_vm.itemsPerPage,"record-type":"Reports"},on:{"nextPage":_vm.nextPage,"previousPage":_vm.previousPage}}):_vm._e()],1)],2)}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }