<template>
  <div class="date-pagination pa-2">
    <BaseButton :disabled="isLoading" @click="$emit('previousDay')"
      >Prev Day</BaseButton
    >
    <BaseButton :disabled="isLoading" @click="$emit('nextDay')"
      >Next Day</BaseButton
    >
  </div>
</template>

<script>
export default {
  name: 'DatePagination',
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
}
</script>
