<template>
  <WithFilters>
    <div
      slot-scope="{ areAnyFiltersOn, clearFilters, foremen, areForemenLoading }"
    >
      <VContainer fluid pa-0>
        <VLayout row wrap justify-center>
          <!-- Don't display this sort option
                if the view is already filtered by date -->
          <VFlex v-if="!$route.query.reportDate" sm4 pa-1>
            <BaseDropdown
              dark
              title="Sort By Date"
              :options="[
                { name: 'Oldest First', value: 'asc' },
                { name: 'Newest First', value: 'desc' },
              ]"
              clearable
              :value="selectedDateSort"
              @click:clear="clearDateSort"
              @selected="selectDateSort"
            />
          </VFlex>
          <VFlex v-if="$route.path !== '/my-reports'" sm4 pa-1>
            <transition name="fade-fast" mode="out-in">
              <BaseSpinner
                v-if="areForemenLoading"
                message="Fetching Foremen..."
                :font-size="10"
                margin="0"
                :size="20"
              />
              <BaseDropdown
                v-else-if="foremen.length"
                dark
                title="Filter By Foreman"
                :options="foremen"
                clearable
                :value="selectedForeman"
                @selected="selectForeman"
                @clear="selectForeman(false)"
              />
            </transition>
          </VFlex>

          <VFlex
            v-if="!['/locked-reports', '/lock-reports'].includes($route.path)"
            sm4
            pa-1
          >
            <BaseDropdown
              dark
              title="Filter By Status"
              :options="convertedReportStatuses"
              clearable
              :value="selectedReportStatus"
              @clear="selectReportStatus(false)"
              @selected="selectReportStatus"
            />
          </VFlex>
          <transition name="fade-fast">
            <VFlex v-if="areAnyFiltersOn" xs12 sm12
              ><BaseButton
                md-icon="clear"
                color="white"
                text-color="black"
                @click="clearFilters"
                >Clear All Filters</BaseButton
              ></VFlex
            ></transition
          >
        </VLayout>
      </VContainer>
    </div>
  </WithFilters>
</template>

<script>
import { ReportStatuses, HumanFields, FilterKeys } from '@constants/knack'

import WithFilters from '@dataProviders/WithFilters'

export default {
  name: 'ReportsListFilters',
  components: { WithFilters },
  props: {},
  data() {
    return {
      selectedForeman: this.$route.query[FilterKeys.FOREMAN] || false,
      selectedReportStatus:
        this.$route.query[FilterKeys.REPORT_STATUS] || false,
      selectedDateSort:
        this.$route.query.sort_field === HumanFields.DAILY_REPORT.REPORT_DATE
          ? this.$route.query.sort_order || 'asc'
          : false,
      convertedReportStatuses: Object.keys(ReportStatuses).map((k) => {
        return { name: ReportStatuses[k], value: ReportStatuses[k] }
      }),
    }
  },
  methods: {
    selectForeman(selection) {
      this.selectedForeman = selection
      this.$router.push({
        query: { ...this.$route.query, [FilterKeys.FOREMAN]: selection },
      })
    }, // selectForeman

    selectReportStatus(selection) {
      this.selectedReportStatus = selection
      this.$router.push({
        query: { ...this.$route.query, [FilterKeys.REPORT_STATUS]: selection },
      })
    }, // selectReportStatus

    selectDateSort(sortDirection) {
      if (typeof sortDirection === 'undefined') {
        this.clearDateSort()
        return false
      }

      this.$router.push({
        query: {
          ...this.$route.query,
          sort_field: HumanFields.DAILY_REPORT.REPORT_DATE,
          sort_order: sortDirection,
        },
      })
    }, // selectDateSort

    clearDateSort() {
      let query = { ...this.$route.query }
      if (query.sort_field === HumanFields.DAILY_REPORT.REPORT_DATE) {
        delete query.sort_field
        delete query.sort_order
      }
      this.$router.push({ query: query })
    }, // clearDateSort
  },
}
</script>
