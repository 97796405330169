<template>
  <Layout>
    <WithReports
      :items-per-page="itemsPerPage"
      :filters="filters"
      :query-params="queryParams"
    >
      <div
        slot-scope="{
          reports,
          areReportsLoading,
          searchReports,
          clearReportsSearch,
          totalPages,
          totalRecords,
          page,
          refreshPage,
        }"
      >
        <PaginationDatePicker />
        <slot name="subtitle"></slot>
        <DatePagination
          v-if="getsDayNav"
          :is-loading="areReportsLoading"
          :items-per-page="itemsPerPage"
          @nextDay="nextDay"
          @previousDay="previousDay"
        />
        <BasePagination
          v-if="reports.length"
          :is-loading="areReportsLoading"
          :page="page"
          :total-pages="totalPages"
          :number-of-records="totalRecords"
          :items-per-page="itemsPerPage"
          record-type="Reports"
          @nextPage="nextPage"
          @previousPage="previousPage"
        />

        <BaseButton
          v-if="hideFilters && !noFilters"
          small
          outline
          color="white"
          @click="forceShowFilters = true"
          >Show Filters &amp; Search</BaseButton
        >

        <template v-else-if="!noFilters">
          <BaseButton
            v-if="['sm', 'xs'].includes($mq)"
            small
            outline
            color="white"
            @click="forceShowFilters = false"
            >hide Filters &amp; Search</BaseButton
          >

          <ReportsListFilters v-if="!noFilters" :hide-filters="hideFilters" />

          <VContainer pa-0>
            <VLayout justify-center>
              <VFlex xs12 sm8>
                <BaseSearch
                  v-if="getsSearch"
                  placeholder="Type to Search Reports"
                  :messages="
                    $route.query.s
                      ? [`Search results for : '${$route.query.s}'`]
                      : []
                  "
                  color="white"
                  :value="$route.query.s"
                  @search="searchReports"
                  @clear="clearReportsSearch"
                /> </VFlex></VLayout
          ></VContainer>
        </template>

        <slot
          name="header-buttons"
          :reports="reports"
          :loading="areReportsLoading"
          :refresh-page="refreshPage"
        ></slot>

        <transition name="fade-fast" mode="out-in" appear>
          <BaseSpinner v-if="areReportsLoading" />
          <ReportsList
            v-else-if="reports && reports.length"
            :reports="reports"
          />
          <p v-else-if="$route.query.s && !reports.length"
            >No reports matched the search "{{ $route.query.s }}".</p
          >
          <p v-else
            >No reports found for this view.
            <template v-if="page && page > 1">
              <br />
              <BaseButton @click="previousPage">Go Back A Page</BaseButton>
            </template>
          </p>
        </transition>

        <transition name="fade-fast" mode="out-in" appear>
          <BasePagination
            v-if="!areReportsLoading"
            :is-loading="areReportsLoading"
            :page="page"
            :total-pages="totalPages"
            :number-of-records="totalRecords"
            :items-per-page="itemsPerPage"
            record-type="Reports"
            @nextPage="nextPage"
            @previousPage="previousPage"
          />
        </transition>
      </div>
    </WithReports>
  </Layout>
</template>

<script>
import Layout from '@layouts/main'
import ReportsList from '@reportsList/ReportsList'
import DatePagination from '@reportsList/DatePagination'
import ReportsListFilters from '@reportsList/ReportsListFilters'
import PaginationDatePicker from '@reportsList/PaginationDatePicker'
import WithReports from '@dataProviders/WithReports'

import dayjs from 'dayjs'

import { createNamespacedHelpers } from 'vuex'
const { mapState: routeState } = createNamespacedHelpers('route')

export default {
  name: 'ReportsListView',
  components: {
    Layout,
    WithReports,
    ReportsList,
    DatePagination,
    ReportsListFilters,
    PaginationDatePicker,
  },
  props: {
    viewTitle: {
      type: String,
      default: 'Viewing Reports',
    },
    itemsPerPage: {
      type: Number,
      required: true,
      default: 12,
    },
    filters: {
      type: [Array, Object],
      default: () => {},
    },
    queryParams: {
      type: Object,
      default: () => {},
    },
    getsSearch: {
      type: Boolean,
      default: false,
    },
    noFilters: {
      type: Boolean,
      default: false,
    },
    getsDayNav: {
      type: Boolean,
      default: false,
    },
  }, // props
  data() {
    return {
      forceShowFilters: false,
    }
  },
  computed: {
    ...routeState(['query']),
    hideFilters() {
      return (this.$mq === 'sm' && !this.forceShowFilters) || this.noFilters
    }, // hideFilters
  },
  methods: {
    nextPage() {
      let pageNum = this.query.page ? Number(this.query.page) : 1
      this.$router.push({
        query: { ...this.$route.query, page: pageNum + 1 },
      })
    }, // nextPage

    previousPage() {
      this.$router.push({
        query: { ...this.$route.query, page: Number(this.query.page) - 1 },
      })
    }, // previousPage

    nextDay() {
      let date = this.query.reportDate
        ? dayjs(this.query.reportDate).format('MM/DD/YYYY')
        : dayjs().format('MM/DD/YYYY')

      this.$router.push({
        query: {
          ...this.$route.query,
          reportDate: dayjs(date)
            .add(1, 'day')
            .format('MM-DD-YYYY'),
        },
      })
    }, // nextDay

    previousDay() {
      let date = this.query.reportDate
        ? dayjs(this.query.reportDate).format('MM/DD/YYYY')
        : dayjs().format('MM/DD/YYYY')

      this.$router.push({
        query: {
          ...this.$route.query,
          reportDate: dayjs(date)
            .subtract(1, 'day')
            .format('MM-DD-YYYY'),
        },
      })
    }, // previousDay
  },
}
</script>
