<script>
import { FilterKeys, HumanFields } from '@constants/knack'
import dayjs from 'dayjs'
export default {
  name: 'WithQuery',
  props: {
    baseFilters: {
      type: [Array, Object],
      default: () => {},
    },
  },
  data() {
    return {}
  },
  computed: {
    filters() {
      let filters = {
        match: 'and',
        rules: [],
      }
      if (this.baseFilters) {
        let customRules =
          typeof this.baseFilters.rules !== 'undefined'
            ? this.baseFilters.rules
            : this.baseFilters
        filters.rules = [...filters.rules, ...customRules]
        if (this.baseFilters.match) {
          filters.match = this.baseFilters.match
        }
      }

      // TODO (ES) : incorporate query params
      // Should this go in WithFilters instead?
      if (this.$route.query[FilterKeys.FOREMAN]) {
        filters.match = 'and'
        filters.rules.push({
          field: HumanFields.DAILY_REPORT.FOREMANS_NAME,
          operator: 'is',
          value: this.$route.query[FilterKeys.FOREMAN],
        })
      }

      if (this.$route.query[FilterKeys.REPORT_STATUS]) {
        filters.match = 'and'
        filters.rules.push({
          field: HumanFields.DAILY_REPORT.REPORT_STATUS,
          operator: 'is',
          value: this.$route.query[FilterKeys.REPORT_STATUS],
        })
      }

      // TODO (ES) : incorporate default query?
      // How to match this with the normal guys

      if (this.$route.query.reportDate) {
        filters.match = 'and'
        filters.rules = [
          {
            field: HumanFields.DAILY_REPORT.REPORT_DATE,
            operator: 'is',
            value: this.$route.query.reportDate,
          },
        ]
      }

      /**
       * Process Date Range Filters
       */
      if (this.$route.query.startDate || this.$route.query.endDate) {
        filters.match = 'and'
        let dateRangeRules = []
        if (this.$route.query.startDate) {
          dateRangeRules.push({
            field: HumanFields.DAILY_REPORT.REPORT_DATE,
            // there is no "is after or before" option with knack?
            type: 'date',
            operator: 'is after',
            value: {
              all_day: false,
              date: dayjs(this.$route.query.startDate)
                .subtract(1, 'day')
                .format('MM/DD/YYYY'),
            },
          })
        }
        if (this.$route.query.endDate) {
          dateRangeRules.push({
            field: HumanFields.DAILY_REPORT.REPORT_DATE,
            // there is no "is before or during" option with knack?
            type: 'date',
            operator: 'is before',
            value: {
              all_day: false,
              date: dayjs(this.$route.query.endDate)
                .add(1, 'day')
                .format('MM/DD/YYYY'),
            },
          })
        }
        filters.rules = [...filters.rules, ...dateRangeRules]
      }

      return filters
    }, // filters
  },
  watch: {},
  methods: {},
  render() {
    return this.$scopedSlots.default({
      filters: this.filters || [],
    })
  },
}
</script>
